import { mapState, mapMutations, mapActions } from "vuex";

export default {
  computed: {
    ...mapState("userReport", ["showView"])
  },
  methods: {
    ...mapMutations("userReport", ["setShowView", "setIndex","setBalance","setUid", "setAuditStatus", "setMemo", "setFlId", "setDebtStatus"]),
    ...mapActions("userReport/verify", ["userInfo","getVerify"]),
    // 查看
    handleView(row, check = true) {
      let superInfo = row.superInfo
      let flInfo = row.flInfo
      if (superInfo.amount < 50) {
        this.$alert('账户余额不能低于50元，请尽快充值!', '提示', {
          confirmButtonText: '确定',
          type: 'warning',
          callback: action => {
            this.$router.push({ path: "/charge" });
          }
        });
      } else {
        if (!check || flInfo.verify_status.real_name) {
          // console.log('superInfo',superInfo)
          // 存储uid
          // this.setUid(flInfo.flId);
          this.setUid(superInfo.userId);
          this.setBalance(superInfo.balance);
          // 存储审核状态
          this.setAuditStatus(flInfo.status);
          // 存储备注信息
          // this.setMemo(row.memo || row.friendListMemo || "");
          // 存储flId
          // this.setFlId(row.flId);
          // 存储资产负债认证状态
          this.setDebtStatus(flInfo.verify_status.assets_info);
          return this.getVerify();
        }

      }
    },
    // 关闭查看弹窗
    closeView() {
      this.setShowView(false);
      this.setIndex();
    }
  },
  mounted() {
    this.setIndex();
    // 切换导航页面时关闭弹框
    this.setShowView(false);
  }
};
