<template>
  <div class="radar document-view">

    <div class="jilu">
      <p class="title ft-bold">在网时长记录</p>
      <p>
        <span>上次生成时间：{{ date }}</span><button class="refreshBtn" size="small" @click="$emit('refresh-tab')">
        刷新报告
      </button>
        <span>￥{{ price }}/次</span>
      </p>
    </div>
    <div style="text-align: center;height: 500px;padding-top:100px;background: #fff">
      <el-row :gutter="12" style="margin-left: 10vw;margin-right: 10vw" v-if="code == '200'">
        <el-col :span="8">
          <el-row :gutter="10" type="flex" class="row-bg" justify="center">
            <el-card class="box-card" style="width: 300px;text-align: center">
              <div slot="header" class="clearfix" style="text-align: left">
                <span>手机号</span>
              </div>
              <div style=";font-size: 20px;font-weight: bold;">
              {{phone}}
              </div>
            </el-card>
          </el-row>
        </el-col>
        <el-col :span="8">
          <el-row :gutter="10" type="flex" class="row-bg" justify="center">
            <el-card class="box-card" style="width: 300px">
              <div slot="header" class="clearfix" style="text-align: left">
                <span>运营商</span>
              </div>
              <div style="color: #b90c0c;font-size: 20px;font-weight: bold;">
              中国{{channel}}
              </div>
            </el-card>
          </el-row>
        </el-col>
        <el-col :span="8">
          <el-row :gutter="10" type="flex" class="row-bg" justify="center">
            <el-card class="box-card" style="width: 300px">
              <div slot="header" class="clearfix" style="text-align: left">
                <span>入网时长</span>
              </div>
              <div style="color: #b90c0c;font-size: 20px;font-weight: bold;">
                {{time}}
              </div>
            </el-card>
          </el-row>
        </el-col>

      </el-row>
      <el-row :gutter="12" style="margin-left: 10vw;margin-right: 10vw;color: red" v-else>

        当前手机号不在网，或为空号
      </el-row>
    </div>
      <div class="operator-bottom">
        <p>*注：本报告仅提供您了解自己的信用状况，请妥善保管，因保管不当造成个人隐私泄露的，本公司将不承担相关责任。</p>
      </div>

  </div>
</template>

<script>
import viewBlock from './view-block';
import riskTip from './risk-tip';

import { mapState, mapGetters, mapActions } from 'vuex';
import format from '@/utils/formatSecond';
import dayjs from 'dayjs';
import service from '../api';
import getReports from '@/mixins/getReports';
// import DocumentGenerator from "@/components/viewBar/reports/document-generator";
export default {
  mixins: [getReports],
  data() {
    return {
      showApprove:false,
      tmwidth:'25%',
      saveData: "",
      loading:false,
      loadingMessage: '数据处理中...',
      smsCount: 0
    };
  },
  computed: {
    ...mapState('userReport/operator', [
      'channel',
      'date',
      'phone',
      'time',
      'code',
    ]),
    ...mapState('userReport', ['uid','price']),
    ...mapState('userReport/verify', ['user', 'userInfo', 'desensitizationData']),
  },
  methods: {
    ...mapActions('userReport/operator', ['getOperator']),
    // 秒数转化时长
    load(refresh = false) {
      this.getOperator(refresh).then(res => res && this.getReports());
    }
  },
  mounted() {
  },
  created() {
    this.load();
    if(document.documentElement.clientWidth < 480){
      this.tmwidth = '90%'
    };
  }
};
</script>

<style lang="scss" scoped>
.radar {
  position: relative;

  &-score {
    width: 65%;
    margin-left: 20px;

    &-item {
      width: 100%;
      text-align: left;
      margin-bottom: 20px;

      &-name {
        width: 75px;
        margin-right: 20px;
      }
    }

    &-tips {
      width: 30%;
      text-align: left;

      h4 {
        margin-bottom: 30px;
      }
    }
  }

  &-search {
    padding: 0 20px;

    .search-sum {
      width: 30%;

      &-top {
        padding: 13px 20px;
        margin-bottom: 2px;

        .sum-num {
          margin-left: 26px;
          color: rgba(64, 158, 255, 1);
        }
      }

      &-bottom {
        padding: 13px 30px;

        &-item {
          .item-num {
            color: rgba(64, 158, 255, 1);
          }
        }
      }
    }

    &-num-block {
      padding-top: 28px;
    }
  }

  &-current {
    padding-left: 20px;

    &-bottom {
      margin-bottom: 10px;

      &-left {
        width: 30%;
        padding: 50px 20px;
        box-sizing: border-box;
        text-align: center;
        margin-right: 5px;
        vertical-align: middle;
      }

      .right-block {
        width: 24%;
        text-align: center;

        &-top {
          padding: 20px;
          margin-bottom: 5px;
        }

        &-bottom {
          padding: 10px;
        }
      }
    }
  }

  &-behavior {
    padding-left: 20px;
  }

  .jilu {
    width: 100%;
    height: 114px;
    background: #ffffff;
    padding: 20px 0;
    box-sizing: border-box;
    text-align: center;

    .title {
      font-size: 24px;
      color: #000000;
    }

    .refreshBtn {
      background-color: #f56c6c;
      padding: 3px 5px;
      color: #ffffff;
      border: none;
      border-radius: 3px;
      cursor: pointer;
      margin: 0 10px;
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 480px) {
  .radar {
    .radarBox {
      display: block;

      .radar-score {
        width: 100%;
        margin-left: 0;
      }

      .radar-score-tips {
        width: 100%;
      }
    }

    &-search {
      display: block;

      .search-sum {
        width: 100%;
      }

      .num-block {
        width: 100% !important;
        margin-bottom: 0.5rem;
      }
    }

    &-cur {
      display: block;

      .ft-medium {
        width: 100%;
        margin-bottom: 0.5rem;
      }
    }

  }

  .radar-current-bottom {
    display: block;
  }
}
</style>
