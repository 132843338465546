<template>
  <div class="probe document-view">
    <document-generator />
    <div class="jilu">
      <p class="title ft-bold">消费画像</p>
      <p>
        <span>上次生成时间：{{ date }}</span><button class="refreshBtn" size="small" @click="$emit('refresh-tab')">
          刷新报告
        </button>
        <span>￥{{ price }}/次</span>
      </p>
    </div>
      <radar-block title="基本信息">
        <div class="fx-m-between fx-wrap" style="text-align: left">
          <num-block class="with-margin" v-for="(item, index) in baseInfo" :key="index" width="23%" :title="item.name"
                     :num="item.value"></num-block>
        </div>
      </radar-block>
    <radar-block title="用户12个月内交易相关信息">
      <div class="radar-behavior">
        <el-table border :data="jiaoyiInfo" style="width: 100%" header-row-class-name="light-head"
                  :row-class-name="tableRowClassName">
          <el-table-column prop="name" label="详情" align="center"></el-table-column>
          <el-table-column prop="n1" label="近3个月" align="center"></el-table-column>
          <el-table-column prop="n2" label="近6个月" align="center"></el-table-column>
          <el-table-column prop="n3" label="近12个月" align="center"></el-table-column>
        </el-table>
      </div>
    </radar-block>
    <radar-block title="用户近12个月的不同行业交易金额">
      <div class="radar-behavior">
        <el-table border :data="qitaList" style="width: 100%" header-row-class-name="light-head"
                  :row-class-name="tableRowClassName">
          <el-table-column prop="name" label="详情" align="center"></el-table-column>
          <el-table-column prop="n1" label="近6个月" align="center"></el-table-column>
          <el-table-column prop="n2" label="近12个月" align="center"></el-table-column>
        </el-table>
      </div>
    </radar-block>

    <radar-block title="用户近12个月的借记卡和贷记卡交易金额与笔数">
      <div class="radar-behavior">
        <el-table border :data="cardList" style="width: 100%" header-row-class-name="light-head"
                  :row-class-name="tableRowClassName">
          <el-table-column prop="name" label="详情" align="center"></el-table-column>
          <el-table-column prop="n1" label="近6个月" align="center"></el-table-column>
          <el-table-column prop="n2" label="近12个月" align="center"></el-table-column>
        </el-table>
      </div>
    </radar-block>
    <radar-block title="用户近12个月的借记卡和贷记卡交易金额与笔数">
    <div class="fx-m-between fx-wrap" style="border: 1px solid #EBEEF5;justify-content:left">

        <el-col :span="8" v-for="(item,index) in otherInfo" :key="index" style="border-bottom: 1px solid #EBEEF5">
          <el-col :span="18" class="c-bg-cyan" style="padding: 15px 5px">
            <span class="xfmc light-head " style="padding: 15px 5px">{{ item.name }}</span>
          </el-col>
          <el-col :span="6" style="padding: 15px 5px">
            <span class="shuzhi" style="padding: 15px 5px">{{ item.val }}</span>
          </el-col>

        </el-col>
    </div>
    </radar-block>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import getReports from "@/mixins/getReports";
import DocumentGenerator from "@/components/viewBar/reports/document-generator";
import radarBlock from "./radar-block";
import numBlock from "./num-block";
export default {
  mixins: [getReports],
  components: {
    DocumentGenerator,radarBlock,numBlock
  },
  methods: {
    ...mapActions("userReport/xiaofei", ["getXiaoFei"]),
    load (refresh = false) {
      this.getXiaoFei(refresh).then((res) => res && this.getReports())
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === 4) {
        return "c-green ft-sz-13 ft-bold";
      } else if (rowIndex === 5) {
        return "c-red ft-sz-13 ft-bold";
      }
      return "ft-sz-13 ft-bold";
    },
  },
  computed: {
    ...mapGetters("userReport/xiaofei", ["baseInfo","jiaoyiInfo","qitaList","cardList","otherInfo"]),
    ...mapState("userReport", ["uid", "price"]),
    ...mapState("userReport/xiaofei", ["date"])
  },
  created () {
    this.load();
  },
};
</script>

<style lang="scss" scoped>

.text-row{
  // margin: 10px 50px 10px 10px;
}
.el-card__body{
  padding: 5px!important;
}
.name{
  font-weight: bold;
  color: #000;
  font-size: 14px;
  white-space: normal;
  display: inline-block;
  width: max-content;
}
.cbody{
  background-color: #f8f8f8;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.tag-red{
  color: rgb(185, 12, 12);
  text-align: center;
  float: right;
  border: 1px solid #efa3a3;
  background-color: #f7e1e1;
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 4px;
  display: inline-block;
  width: 48px;
}

.tag-green{
  color: rgb(103, 193, 57);
  text-align: center;
  float: right;
  border: 1px solid rgb(103, 193, 57);
  background-color: rgb(223, 234, 218);
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 4px;
  display: inline-block;
  width: 48px;
}

.probe {
  background-color: #fff;
  padding: 20px 22px 50px;
  position: relative;

  .with-margin {
    margin-bottom: 10px;
  }

  .jilu {
    width: 100%;
    height: 114px;
    background: #ffffff;
    padding: 20px 0;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 10px;

    .title {
      font-size: 24px;
      color: #000000;
    }

    .refreshBtn {
      background-color: #f56c6c;
      padding: 3px 5px;
      color: #ffffff;
      border: none;
      border-radius: 3px;
      cursor: pointer;
      margin: 0 10px;
      font-size: 14px;
    }
  }

}
.with-margin{
  width: 50%;
  display: inline-block;
  .xfmc{
    display: inline-block;
    width: 55%;
    background-color: #ecf2f9;
    text-indent: 20px;
    height: 55px;
    line-height: 55px;
    border-bottom: 1px solid #dae2ea;
  }
  .shuzhi{
    display: inline-block;
    width: 45%;
    background-color: #f9f1ed;
    text-indent: 20px;
    height: 55px;
    line-height: 55px;
    border-bottom: 1px solid #e6ded9;
  }
}
</style>
