<template>
  <div class="risk">
    <div class="risk-head fx-m-between">
      <div class="title ft-sz-15 fx-v-center">
        <img class="title-icon" src="@/assets/userManage/risk.png" />
        <span>风险提示</span>
      </div>
      <p class="ft-sz-13 ft-bold c-red" v-if="riskTip">
        <span class="c-black">（仅供参考）</span>
        <span>风险提示: {{riskTip}}</span>
      </p>
      <div>
        <button class="refreshBtn" @click="$emit('refreshOperator')">刷新报告</button>
        <document-generator split="true" style="display: inline-block"/>
      </div>
    </div>
    <div class="risk-body ft-bold fx-m-between">
      <view-charts
        v-for="(item, index) in charts"
        :key="index"
        :name="item.name"
        :chart-data="item.chartData"
      ></view-charts>
    </div>
  </div>
</template>

<script>
import viewCharts from './view-charts';
import DocumentGenerator from "@/components/viewBar/reports/document-generator";
export default {
  props: {
    charts: {
      type: Array,
      default: () => []
    },
    riskTip: {
      type: String,
      default: ''
    }
  },
  components: {
    viewCharts,
    DocumentGenerator
  }
};
</script>

.<style lang="scss" scoped>
.risk {
  //margin-top: 15px;
  margin-bottom: 15px;
  .title {
    //margin-bottom: 15px;
    img {
      width: 24px;
      height: auto;
      margin-right: 13px;
    }
  }
  &-head {
    padding: 18px 20px 18px 20px;
    background-color: #fff;
    margin-bottom: 2px;
    p{
      position: relative;
      top:10px;
    }
  }
  &-body {
    // padding: 28px 80px;
    // background-color: #fff;
  }
  .refreshBtn {
    display: inline-block;
    background-color: #f56c6c;
    padding: 5px 10px;
    color: #ffffff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    margin: 0 20px;
    position:relative;
    top:-8px;
  }
}
@media screen and (max-width: 480px) {
  .risk-body{
    display: block;

  }

}
</style>
