<template>
  <div class="radar document-view">

    <div class="jilu">
      <p class="title ft-bold">全景档案</p>
      <p>
        <span>上次生成时间：{{ date }}</span><button class="refreshBtn" size="small" @click="$emit('refresh-tab')">
        刷新报告
      </button>
        <span>￥{{ price }}/次</span>
      </p>
    </div>
    <div style="text-align: center;padding-top:10px;background: #fff">
      <radar-block title="结果详情">
        <div class="radar-behavior">
          <el-table border :data="formData1" style="width: 100%" header-row-class-name="light-head"
                    :row-class-name="tableRowClassName">
            <el-table-column prop="member_count" label="逾期机构数(家)" align="center"></el-table-column>
            <el-table-column prop="order_count" label="逾期订单数(笔)" align="center"></el-table-column>
            <el-table-column prop="debt_amount" label="逾期总金额(元)" align="center"></el-table-column>
            <el-table-column prop="current_org_count" label="近1月共债机构数(家)" align="center"></el-table-column>
            <el-table-column prop="current_order_count" label="近1月共债订单数(笔)" align="center"></el-table-column>
            <el-table-column prop="current_order_amt" label="近1月共债订单还款金额(元)" align="center"></el-table-column>
            <el-table-column prop="current_order_lend_amt" label="近1月共债订单金额(元)" align="center"></el-table-column>

          </el-table>
        </div>
      </radar-block>

      <radar-block title="逾期详情">
        <div class="radar-behavior">
          <el-table border :data="debt_detail" style="width: 100%" header-row-class-name="light-head"
                    :row-class-name="tableRowClassName">
            <el-table-column prop="endDay" label="逾期时间" align="center"></el-table-column>
            <el-table-column prop="billType" label="逾期账期数" align="center"></el-table-column>
            <el-table-column prop="endMoney" label="逾期金额(元)" align="center"></el-table-column>
            <el-table-column prop="endFlag" label="是否结清" align="center">
              <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.endFlag == 'Y'">已结清</el-tag>
                <el-tag type="danger" v-else>未结清</el-tag>
              </template>
            </el-table-column>

          </el-table>
        </div>
      </radar-block>

      <radar-block title="历史共债">
        <div class="radar-behavior">
          <el-table border :data="totaldebt_detail" style="width: 100%" header-row-class-name="light-head"
                    :row-class-name="tableRowClassName">
            <el-table-column prop="totaldebt_date" label="共债统计时间范围" align="center"></el-table-column>
            <el-table-column prop="totaldebt_org_count" label="共债机构数(家)" align="center"></el-table-column>
            <el-table-column prop="totaldebt_order_count" label="共债订单数(笔)" align="center"></el-table-column>
            <el-table-column prop="totaldebt_order_amt" label="共债订单已还金额(元)" align="center"></el-table-column>
            <el-table-column prop="new_or_old" label="疑似借新还旧" align="center">
              <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.new_or_old == 'Y'">是</el-tag>
                <el-tag type="danger" v-else>否</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="totaldebt_order_lend_amt" label="共债订单金额(元)" align="center"></el-table-column>

          </el-table>
        </div>
      </radar-block>

<!--      <radar-block title="查询详情">-->
<!--        <div class="radar-behavior">-->
<!--          <el-table border :data="buildUUData" style="width: 100%" header-row-class-name="light-head" :span-method="objectSpanMethod"-->
<!--                    :row-class-name="tableRowClassName">-->
<!--            <el-table-column prop="member_count" label="逾期机构数" align="center"></el-table-column>-->
<!--            <el-table-column prop="n2" label="逾期订单数" align="center"></el-table-column>-->
<!--            <el-table-column prop="m1" label="近7天" align="center"></el-table-column>-->
<!--            <el-table-column prop="m2" label="近1个月" align="center"></el-table-column>-->
<!--            <el-table-column prop="m3" label="近3个月" align="center"></el-table-column>-->
<!--            <el-table-column prop="m4" label="近6个月" align="center"></el-table-column>-->

<!--          </el-table>-->
<!--        </div>-->
<!--      </radar-block>-->
    </div>
      <div class="operator-bottom">
        <p>*注：本报告仅提供您了解自己的信用状况，请妥善保管，因保管不当造成个人隐私泄露的，本公司将不承担相关责任。</p>
      </div>

  </div>
</template>

<script>

import { mapState, mapGetters, mapActions } from 'vuex';
import getReports from '@/mixins/getReports';
import radarBlock from "./radar-block";
import numBlock from "./num-block";

// import DocumentGenerator from "@/components/viewBar/reports/document-generator";
export default {
  mixins: [getReports],
  components: {
    radarBlock,
  },
  data() {
    return {
      showApprove:false,
      tmwidth:'25%',
      saveData: "",
      loading:false,
      loadingMessage: '数据处理中...',
      smsCount: 0
    };
  },
  computed: {

    ...mapGetters('userReport/object', [
      "formData1"

    ]),
    ...mapState('userReport/object', [
      'debt_detail','totaldebt_detail'
    ]),
    ...mapState("userReport", ["uid", "price"]),
    ...mapState("userReport/object", {
      provider: "provider",
      applyDetail: "apply_report_detail",
      date: "date"
    }),


  },
  watch: {
    ovdure_org_money: {
      handler(val) {
        console.log(val)
      }
    }
  },
  methods: {
    ...mapActions('userReport/object', ['getObject']),
    // 秒数转化时长
    load(refresh = false) {
      this.getObject(refresh).then(res => res && this.getReports());
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex % 2 === 0) {
          return {
            rowspan: 2,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
    tableRowClassName({ row, rowIndex }) {
      // if (rowIndex === 4) {
      //   return "c-green ft-sz-13 ft-bold";
      // } else if (rowIndex === 5) {
      //   return "c-red ft-sz-13 ft-bold";
      // }
      return "ft-sz-13 ft-bold";
    },
  },
  mounted() {
  },
  created() {
    this.load();
    // if(document.documentElement.clientWidth < 480){
    //   this.tmwidth = '90%'
    // };
  }
};
</script>

<style lang="scss" scoped>
.radar {
  position: relative;

  &-score {
    width: 65%;
    margin-left: 20px;

    &-item {
      width: 100%;
      text-align: left;
      margin-bottom: 20px;

      &-name {
        width: 75px;
        margin-right: 20px;
      }
    }

    &-tips {
      width: 30%;
      text-align: left;

      h4 {
        margin-bottom: 30px;
      }
    }
  }

  &-search {
    padding: 0 20px;

    .search-sum {
      width: 30%;

      &-top {
        padding: 13px 20px;
        margin-bottom: 2px;

        .sum-num {
          margin-left: 26px;
          color: rgba(64, 158, 255, 1);
        }
      }

      &-bottom {
        padding: 13px 30px;

        &-item {
          .item-num {
            color: rgba(64, 158, 255, 1);
          }
        }
      }
    }

    &-num-block {
      padding-top: 28px;
    }
  }

  &-current {
    padding-left: 20px;

    &-bottom {
      margin-bottom: 10px;

      &-left {
        width: 30%;
        padding: 50px 20px;
        box-sizing: border-box;
        text-align: center;
        margin-right: 5px;
        vertical-align: middle;
      }

      .right-block {
        width: 24%;
        text-align: center;

        &-top {
          padding: 20px;
          margin-bottom: 5px;
        }

        &-bottom {
          padding: 10px;
        }
      }
    }
  }

  &-behavior {
    padding-left: 20px;
  }

  .jilu {
    width: 100%;
    height: 114px;
    background: #ffffff;
    padding: 20px 0;
    box-sizing: border-box;
    text-align: center;

    .title {
      font-size: 24px;
      color: #000000;
    }

    .refreshBtn {
      background-color: #f56c6c;
      padding: 3px 5px;
      color: #ffffff;
      border: none;
      border-radius: 3px;
      cursor: pointer;
      margin: 0 10px;
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 480px) {
  .radar {
    .radarBox {
      display: block;

      .radar-score {
        width: 100%;
        margin-left: 0;
      }

      .radar-score-tips {
        width: 100%;
      }
    }

    &-search {
      display: block;

      .search-sum {
        width: 100%;
      }

      .num-block {
        width: 100% !important;
        margin-bottom: 0.5rem;
      }
    }

    &-cur {
      display: block;

      .ft-medium {
        width: 100%;
        margin-bottom: 0.5rem;
      }
    }

  }

  .radar-current-bottom {
    display: block;
  }
}
</style>
