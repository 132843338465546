import { render, staticRenderFns } from "./view-xiaofei.vue?vue&type=template&id=147fd5cd&scoped=true"
import script from "./view-xiaofei.vue?vue&type=script&lang=js"
export * from "./view-xiaofei.vue?vue&type=script&lang=js"
import style0 from "./view-xiaofei.vue?vue&type=style&index=0&id=147fd5cd&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "147fd5cd",
  null
  
)

export default component.exports